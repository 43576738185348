import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';
import { getFlowName, pickFoldedErrorResponse } from '../utils/helpers';
import amplitudeClient from '../utils/amplitude';
import gtmAddEvent from '../utils/gtmAddEvent';

import {
  logSuccess,
  logFailure,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  saveTokenSuccess,
  getUserDataSuccess,
  getUserDataFailure,
  updateUserDataSuccess,
  updateUserDataFailure,
} from './userActions';
import { setEmail } from '../onboard/onboardActions';
import moment from 'moment';

const { AUTH_KEY, USER_UUID_KEY, LOCALSTORAGE_EXPIRES_KEY } =
  require('../constants').default;

const {
  SAVE_TOKEN_REQUEST,
  LOG_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  GET_USER_DATA_REQUEST,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
} = require('./userActions').constants;

function* saveSessionToken(action) {
  const authDate = new Date();
  lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
  lockr.set(AUTH_KEY, action.token);
  lockr.set(USER_UUID_KEY, action.uuid);
  yield put(saveTokenSuccess());
}

function* makeLoginRequest(action) {
  try {
    const response = yield call(fetchApi, {
      method: 'POST',
      url: '/api/onboard',
      body: action.payload,
    });
    // save Bearer for the newAccount request
    yield call(saveSessionToken, {
      token: response.data.access_token,
    });

    const newAccount = yield call(fetchApiAuth, {
      method: 'GET',
      url: '/api/user/current',
    });

    // tracking
    window?.dataLayer?.push({
      event: 'Purchase registration',
      eventCategory: 'Sign up',
      eventAction: newAccount.data.data.uuid,
    });

    if (newAccount.data.data.uuid) {
      window.hj('identify', newAccount.data.data.uuid, {
        'Signed up': moment().toISOString(),
      });
      amplitudeClient.setUserId(newAccount.data.data.uuid);
      amplitudeClient.setUserProperties({ email: newAccount.data.data.email });
      amplitudeClient.logEvent('Start Registration', {
        email: newAccount.data.data.email,
      });
      window?.dataLayer?.push({ customdimension: newAccount.data.data.email });
      window?.MC_PIXEL?.fireLogConversionEvent('user_registered');
    }

    yield call(saveSessionToken, {
      uuid: newAccount.data.data.uuid,
      token: response.data.access_token,
    });

    yield put(loginSuccess(response));
    // redirect after registration
    if (response.data.access_token && newAccount.data.data.email) {
      yield put(setEmail(newAccount.data.data.email));
      gtmAddEvent('MailRequestPage', 'email', newAccount.data.data.email);
      const flow = window.location.pathname.includes('admin')
        ? 'admin'
        : getFlowName();
      let route;
      switch (flow) {
        case 'admin':
          route = '/admin-transit';
          break;
        default:
          if (!route) {
            route = 'transit';
          }
          break;
      }
      history.push(route);
    }
  } catch (e) {
    console.log(e);
    yield put(loginFailure(pickFoldedErrorResponse(e, 'email')));
  }
}

function* makeLogRequest(action) {
  try {
    const response = yield call(fetchApi, {
      method: 'POST',
      url: '/api/login',
      body: action.payload,
    });

    // save Bearer for the account request
    yield call(saveSessionToken, {
      token: response.data.access_token,
    });

    const newAccount = yield call(fetchApiAuth, {
      method: 'GET',
      url: '/api/user/current',
    });

    yield call(saveSessionToken, {
      uuid: newAccount.data.data.uuid,
      token: response.data.access_token,
    });

    yield put(logSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(logFailure(pickFoldedErrorResponse(e, 'email')));
  }
}

function* logoutRequest() {
  yield put(logoutSuccess());
  localStorage.clear();
  history.push('/');
}

function* getUserData() {
  try {
    const response = yield call(fetchApiAuth, {
      method: 'GET',
      url: '/user/user-data',
    });
    yield put(getUserDataSuccess(response));
  } catch (e) {
    yield put(getUserDataFailure(e.response ? e.response.data.message : e));
  }
}

function* updateUserData(action) {
  try {
    const response = yield call(fetchApiAuth, {
      method: 'POST',
      url: '/api/user/current',
      body: action.payload,
    });
    yield put(setEmail(response?.data?.data?.email));
    yield put(updateUserDataSuccess(response));
  } catch (e) {
    console.log(JSON.stringify(e?.message || e?.response?.data?.message || e));
    amplitudeClient.logEvent('Registration page error', {
      message: e?.message || e?.response?.data?.message || e,
    });
    yield put(
      updateUserDataFailure(
        e?.response ? e?.response?.data?.message : e?.message
      )
    );
  }
}

function handleUserDataSuccess(res) {
  amplitudeClient.logEvent('Registration', {
    registered: 'email',
    userId: res?.payload?.data?.data?.uuid,
    registrationDate: new Date(),
  });
  window?.dataLayer?.push({
    userId: res?.payload?.data?.data?.uuid,
  });
  history.push('instruction');
}

function handleUserDataFailure(error) {
  message.error(
    JSON.stringify(error?.payload) ||
      'Something has gone wrong. Please contact us'
  );
}

/**
 * Watch actions
 */
export default function* userSaga() {
  yield all([
    takeEvery(SAVE_TOKEN_REQUEST, saveSessionToken),
    takeEvery(LOG_REQUEST, makeLogRequest),
    takeEvery(LOGIN_REQUEST, makeLoginRequest),
    takeEvery(LOGOUT_REQUEST, logoutRequest),
    takeEvery(GET_USER_DATA_REQUEST, getUserData),
    takeEvery(UPDATE_USER_DATA_REQUEST, updateUserData),
    takeEvery(UPDATE_USER_DATA_SUCCESS, handleUserDataSuccess),
    takeEvery(UPDATE_USER_DATA_FAILURE, handleUserDataFailure),
  ]);
}
