import { combineReducers } from 'redux';
import global from './global/globalReducer';
import payment from './payment/paymentReducer';
import user from './user/userReducer';
import onboard from './onboard/onboardReducer';

import history from './utils/history';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
  // ... your other reducers here ...
  global,
  user,
  onboard,
  payment,
});

const rootReducer = (state, action) => {
  // ... your other reducers here ...
  if (action.type.indexOf('_FAILURE') !== -1) {
    const { payload } = action;
    // message.error(typeof payload === 'string' ? payload : 'Something has gone wrong');
    if (payload === 'Unauthenticated.') {
      history.push('/logout');
    }
  }

  return appReducers(state, action);
};

export default rootReducer;
