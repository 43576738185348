// .env constants
const {
  REACT_APP_ENV,

  REACT_APP_URL,
  REACT_APP_API_URL,

  REACT_APP_BUGSNAG_KEY,
  REACT_APP_GOOGLE_MAPS_KEY,
} = process.env;

// APP CONSTATNS
const app = {
  ENV: REACT_APP_ENV,
  DEBUG: REACT_APP_ENV === 'local',
  MOCK: false,
  BACKEND: 'laravel', // or 'laravel'

  APP_NAME: 'StrongHer',

  APP_URL: REACT_APP_URL,
  API_URL: REACT_APP_API_URL,

  BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
  GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,

  AUTH_KEY: 'auth-key',
  USER_UUID_KEY: 'user-uuid-key',
  LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
  LOCALSTORAGE_EXPIRES_TIME: '30', // minutes

  BRAINTREE_PAY_DATA: null,
  BRAINTREE_DEVICE_DATA: null,
  BRAINTREE_NONCE: null,
};

export default app;
