export default function gtmAddEvent(page, eventName, eventVal) {
  const tagManagerArgs = {
    dataLayer: {
      [eventName]: eventVal,
      page,
    },
    dataLayerName: 'PageDataLayer',
  };
  window?.dataLayer?.push(tagManagerArgs);
}
