import { Suspense } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { CoreRouter, LogoutRoute, DefaultRoute } from './utils/coreRouter';
import { lazyWithPreload } from './utils/helpers';
import DefaultPage2 from '../pages/DefaultPage2';

// const DefaultPage = lazyWithPreload(() => import('../pages/DefaultPage'));
// const DefaultPage3 = lazyWithPreload(() => import('../pages/DefaultPage3'));
const OnboardingPage = lazyWithPreload(() => import('../pages/OnboardingPage'));
// const ChoosePlanPage = lazyWithPreload(() => import('../pages/ChoosePlanPage'));
const SignUpPage = lazyWithPreload(() => import('../pages/SignUpPage'));
const Registration = lazyWithPreload(() => import('../pages/Registration'));
const ThankYouPage = lazyWithPreload(() => import('../pages/ThankYouPage'));
const ThankYouPage2 = lazyWithPreload(() => import('../pages/ThankYouPage2'));
const ContactUsPage = lazyWithPreload(() => import('../pages/ContactUsPage'));
// const ChooseTrialPage = lazyWithPreload(() => import('../pages/ChooseTrialPage'));
const ChooseSimplePlanPage = lazyWithPreload(() =>
  import('../pages/ChooseSimplePlanPage')
);
const MailRequestPage = lazyWithPreload(() =>
  import('../pages/MailRequestPage')
);
const OfferPage = lazyWithPreload(() => import('../pages/OfferPage'));
const UpgradeOfferPage = lazyWithPreload(() =>
  import('../pages/UpgradeOfferPage')
);
const TransitPage = lazyWithPreload(() => import('../pages/TransitPage'));

const PaywallPage = lazyWithPreload(() => import('../pages/PaywallPage'));
const AdminPage = lazyWithPreload(() => import('../pages/AdminPage'));
const AdminTransitPage = lazyWithPreload(() =>
  import('../pages/AdminTransitPage')
);

const Routes = ({ persistor }) => {
  return (
    <Suspense fallback="">
      <CoreRouter>
        {/* Routes without authentication */}
        <DefaultRoute exact path="/flow1/" component={DefaultPage2} />
        <DefaultRoute exact path="/flow2/" component={DefaultPage2} />
        <DefaultRoute exact path="/flow9/" component={DefaultPage2} />

        <DefaultRoute
          exact
          path="/flow1/contact-us/"
          component={ContactUsPage}
        />
        <DefaultRoute
          exact
          path="/flow2/contact-us/"
          component={ContactUsPage}
        />

        <DefaultRoute
          exact
          path="/flow1/onboarding"
          component={OnboardingPage}
        />
        <DefaultRoute
          exact
          path="/flow2/onboarding"
          component={OnboardingPage}
        />
        <DefaultRoute
          exact
          path="/flow9/onboarding"
          component={OnboardingPage}
        />

        <DefaultRoute exact path="/flow1/sign-up" component={SignUpPage} />
        <DefaultRoute exact path="/flow2/sign-up" component={SignUpPage} />
        <DefaultRoute exact path="/flow9/sign-up" component={SignUpPage} />

        <DefaultRoute
          exact
          path="/flow1/registration"
          component={Registration}
        />
        <DefaultRoute
          exact
          path="/flow2/registration"
          component={Registration}
        />
        <DefaultRoute
          exact
          path="/flow9/registration"
          component={Registration}
        />

        <DefaultRoute exact path="/flow1/thank-you" component={ThankYouPage} />
        <DefaultRoute exact path="/flow2/thank-you" component={ThankYouPage} />
        <DefaultRoute exact path="/flow9/thank-you" component={ThankYouPage} />

        <DefaultRoute
          exact
          path="/flow1/instruction"
          component={ThankYouPage2}
        />
        <DefaultRoute
          exact
          path="/flow2/instruction"
          component={ThankYouPage2}
        />
        <DefaultRoute
          exact
          path="/flow9/instruction"
          component={ThankYouPage2}
        />

        <DefaultRoute
          exact
          path="/flow1/mail-request"
          component={MailRequestPage}
        />
        <DefaultRoute
          exact
          path="/flow2/mail-request"
          component={MailRequestPage}
        />
        <DefaultRoute
          exact
          path="/flow9/mail-request"
          component={MailRequestPage}
        />

        <DefaultRoute
          exact
          path="/flow1/choose-plan"
          component={ChooseSimplePlanPage}
        />
        <DefaultRoute
          exact
          path="/flow2/choose-plan"
          component={ChooseSimplePlanPage}
        />
        <DefaultRoute
          exact
          path="/flow9/choose-plan"
          component={ChooseSimplePlanPage}
        />

        <DefaultRoute exact path="/flow1/offer" component={OfferPage} />
        <DefaultRoute exact path="/flow2/offer" component={OfferPage} />
        <DefaultRoute
          exact
          path="/flow1/upgrade-offer"
          component={UpgradeOfferPage}
        />
        <DefaultRoute
          exact
          path="/flow2/upgrade-offer"
          component={UpgradeOfferPage}
        />
        <DefaultRoute exact path="/flow9/offer" component={OfferPage} />
        <DefaultRoute exact path="/flow1/transit" component={TransitPage} />
        <DefaultRoute exact path="/flow2/transit" component={TransitPage} />
        <DefaultRoute exact path="/flow9/transit" component={TransitPage} />

        <DefaultRoute exact path="/admin" component={AdminPage} />
        <DefaultRoute
          exact
          path="/admin-transit"
          component={AdminTransitPage}
        />
        <DefaultRoute exact path="/paywall" component={PaywallPage} />
        <DefaultRoute exact path="/admin/thank-you" component={ThankYouPage} />

        {/* Routes where authentication is required */}
        {/* <AuthRoute exact path="/contact" component={Contact} /> */}

        {/* Logout route */}
        <LogoutRoute exact path="/logout" persistor={persistor} />
        <Redirect
          exact
          from="/"
          to={{
            pathname: '/flow1/',
            state: { query: window.location.search },
          }}
        />
      </CoreRouter>
    </Suspense>
  );
};

Routes.defaultProps = {
  persistor: null,
};

Routes.propTypes = {
  persistor: PropTypes.object,
};

export default Routes;
