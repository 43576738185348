import { Record } from 'immutable';

const {
  DELETE_TOKEN_REQUEST,
  DELETE_TOKEN_SUCCESS,

  SAVE_TOKEN_REQUEST,
  SAVE_TOKEN_SUCCESS,

  SAVE_SUBSCRIPTION_TOKEN,

  LOG_REQUEST,
  LOG_SUCCESS,
  LOG_FAILURE,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,

  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,

  SET_LOCATION,
  SET_UTM_DATA,
  SET_PROMOCODE,
  SET_PROMO_PRICE,
} = require('./userActions').constants;

const InitialState = Record({
  error: null,
  isFetching: false,
  isLoggedIn: false,
  loginUser: '',
  userSettings: {},
  userData: {},
  userFetchedAt: null,
  mapLocation: null,
  utmData: {},
  promocode: null,
  promoPrice: null,
  subscriptionToken: {
    token: null,
  },
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (type) {
    case LOGIN_REQUEST:
    case LOG_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case GET_USER_DATA_REQUEST:
    case UPDATE_USER_DATA_REQUEST:
      return state.set('isFetching', true).set('error', null);
    case SAVE_TOKEN_SUCCESS:
    case LOG_SUCCESS:
    case LOGIN_SUCCESS:
      return state
        .set('isFetching', false)
        .set('userFetchedAt', () => {
          const date = new Date();
          return date.toISOString();
        })
        .set(
          'loginUser',
          payload && payload.data && payload.data.username
            ? payload.data.username
            : 'Logged In User'
        )
        .set(
          'userSettings',
          payload && payload.data && payload.data.settings
            ? payload.data.settings
            : {}
        )
        .set('isLoggedIn', true);
    case SAVE_SUBSCRIPTION_TOKEN:
      return state.set('subscriptionToken', payload);
    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
      return state
        .set('isFetching', false)
        .set('userFetchedAt', null)
        .set('loginUser', '')
        .set('isLoggedIn', false);
    case GET_USER_DATA_SUCCESS:
    case UPDATE_USER_DATA_SUCCESS:
      return state.set('isFetching', false).set('userData', payload.data);

    case LOG_FAILURE:
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
      return state
        .set('isFetching', false)
        .set('isLoggedIn', false)
        .set('error', payload);
    case GET_USER_DATA_FAILURE:
    case UPDATE_USER_DATA_FAILURE:
      return state.set('isFetching', false).set('error', payload);
    case SET_LOCATION:
      return state.set('mapLocation', payload.value);
    case SET_UTM_DATA:
      return state.set('utmData', payload);
    case SET_PROMOCODE:
      return state.set('promocode', payload);
    case SET_PROMO_PRICE:
      return state.set('promoPrice', payload);

    default:
      return state;
  }
}
