import axios from 'axios';
import qs from 'qs';
import lockr from 'lockr';
import { isUndefined, isEmpty, isObject } from 'underscore';

// load mock data if enabled
import './mock';

const {
  API_URL,
  AUTH_KEY,
  USER_UUID_KEY,
  BACKEND,
  GOOGLE_MAPS_KEY,
  LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

// ----- Helpers ----- //
const apiUrlInjected = API_URL;

// Get Api Url
const getApiUrl = () => apiUrlInjected;

// Get hostname url
const getBaseUrl = () => {
  const apiUrl = apiUrlInjected;
  return apiUrl.split('/api')[0];
};

// defaults for axios
const api = axios.create({
  baseURL: apiUrlInjected,
});

// Get locational data
const locationData = (name) =>
  axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`
  );

// Format params for api call
const formatParams = (payload, key) => {
  let params = payload;
  if (!isObject(payload)) {
    params = {};
    params[key || 'id'] = payload;
  }

  return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);
const getUserUuid = () => lockr.get(USER_UUID_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //

function fetchApi(opts, headers) {
  let data = opts.method.toUpperCase() === 'GET' ? null : opts.body;
  let laravelHeaders = {};

  if (BACKEND === 'yii') {
    data = data ? qs.stringify(data) : null;
  } else {
    laravelHeaders = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
  }

  const options = {
    method: opts.method,
    url: opts.url,
    params: opts.params,
    headers: laravelHeaders,
    data,
  };

  if (!isEmpty(headers)) {
    options.headers = { ...options.headers, ...headers };
  }

  return api(options);
}

function fetchApiAuth(opts) {
  return new Promise((resolve, reject) => {
    if (isUndefined(getAuthKey())) {
      reject(new Error('not-authorised'));
    } else {
      const authDate = new Date();
      lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
      resolve(fetchApi(opts, getAuthHeaders()));
    }
  });
}

export {
  getApiUrl,
  getBaseUrl,
  locationData,
  formatParams,
  // -- Auth Key Helpers --//
  getAuthKey,
  getAuthHeaders,
  getUserUuid,
  fetchApi,
  fetchApiAuth,
};
