import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
// import immutableTransform from 'redux-persist-transform-immutable';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
// import { rehydrationCompleted } from './global/globalActions';

/**
 * Reducer
 */
import reducer from './reducers';

/**
 * Sagas
 */
import rootSagas from './sagas';

/**
 * ## configureStore
 * @param {Object} the state
 */
const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, thunk))
  );
  // to backup state in local storage and restore on load
  // const persist = persistStore(store, {
  //     transforms: [immutableTransform()],
  //     blacklist: ['global']
  // }, () => {
  //     store.dispatch(rehydrationCompleted(true));
  // });
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSagas);

  return { store, persistor };
}
