import { Record } from 'immutable';

const { SET_CARD_COMPLETED } = require('./paymentActions').constants;

const InitialState = Record({
  cardCompleted: false,
});

const initialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function paymentReducer(
  state = initialState,
  { payload, type }
) {
  if (!(state instanceof InitialState)) return initialState.merge(state);
  switch (type) {
    case SET_CARD_COMPLETED:
      return state.set('cardCompleted', payload);
    default:
      return state;
  }
}
