import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
  DELETE_TOKEN_REQUEST: null,
  DELETE_TOKEN_SUCCESS: null,

  SAVE_TOKEN_REQUEST: null,
  SAVE_TOKEN_SUCCESS: null,

  SAVE_SUBSCRIPTION_TOKEN: null,

  LOG_REQUEST: null,
  LOG_SUCCESS: null,
  LOG_FAILURE: null,

  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  GET_USER_DATA_REQUEST: null,
  GET_USER_DATA_SUCCESS: null,
  GET_USER_DATA_FAILURE: null,

  UPDATE_USER_DATA_REQUEST: null,
  UPDATE_USER_DATA_SUCCESS: null,
  UPDATE_USER_DATA_FAILURE: null,

  SET_PROMOCODE: null,
  SET_PROMO_PRICE: null,

  SET_LOCATION: null,
  SET_UTM_DATA: null,

  CARD_COMPLETED: null,
});

export const { deleteTokenRequest, deleteTokenSuccess } = createActions(
  constants.DELETE_TOKEN_REQUEST,
  constants.DELETE_TOKEN_SUCCESS
);

export const { saveTokenRequest, saveTokenSuccess } = createActions(
  constants.SAVE_TOKEN_REQUEST,
  constants.SAVE_TOKEN_SUCCESS
);

export const { setPromocode } = createActions(constants.SET_PROMOCODE);
export const { setPromoPrice } = createActions(constants.SET_PROMO_PRICE);

export const { saveSubscriptionToken } = createActions(
  constants.SAVE_SUBSCRIPTION_TOKEN
);

export const { loginRequest, loginSuccess, loginFailure } = createActions(
  constants.LOGIN_REQUEST,
  constants.LOGIN_SUCCESS,
  constants.LOGIN_FAILURE
);

export const { logRequest, logSuccess, logFailure } = createActions(
  constants.LOG_REQUEST,
  constants.LOG_SUCCESS,
  constants.LOG_FAILURE
);

export const { logoutRequest, logoutSuccess, logoutFailure } = createActions(
  constants.LOGOUT_REQUEST,
  constants.LOGOUT_SUCCESS,
  constants.LOGOUT_FAILURE
);

export const { getUserDataRequest, getUserDataSuccess, getUserDataFailure } =
  createActions(
    constants.GET_USER_DATA_REQUEST,
    constants.GET_USER_DATA_SUCCESS,
    constants.GET_USER_DATA_FAILURE
  );

export const {
  updateUserDataRequest,
  updateUserDataSuccess,
  updateUserDataFailure,
} = createActions(
  constants.UPDATE_USER_DATA_REQUEST,
  constants.UPDATE_USER_DATA_SUCCESS,
  constants.UPDATE_USER_DATA_FAILURE
);

export const { setLocation } = createActions(constants.SET_LOCATION);
export const { setUtmData } = createActions(constants.SET_UTM_DATA);
