import { createRef, useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { gsap } from 'gsap';
import { useDispatch } from 'react-redux';
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import { Picture } from 'react-responsive-picture';
import queryString from 'query-string';
import history from '../../core/utils/history';
import amplitudeClient, {
  setAmplUserPropsFromUrlQuery,
} from '../../core/utils/amplitude';
import {
  getFlowName,
  addTrailSlashToUrl,
  lazyWithPreload,
} from '../../core/utils/helpers';
import srcStepPic1 from '../../assets/images/model2.png';
import srcWebpBg from '../../assets/images/melissa_lg.webp';
import srcPngBg from '../../assets/images/melissa_lg.png';
import srcPngXsBg from '../../assets/images/melissa-full.png';
import srcWebpXsBg from '../../assets/images/melissa-full.webp';
import srcCardiogram from '../../assets/images/icon-home-cardiogram.svg';
import srcWaist from '../../assets/images/icon-home-waist.svg';
import { setUtmData } from '../../core/user/userActions';
import Header2 from '../../components/layout/Header2';
import srcLogo from '../../assets/images/strongher-logo.svg';
import srcHelpIcon from '../../assets/images/contact_us.svg';
import srcPreloaderLogo from '../../assets/images/logo.png';

import './style.scss';

const OnboardingPage = lazyWithPreload(() =>
  import('../../pages/OnboardingPage')
);

const DefaultPage = (props) => {
  const dispatch = useDispatch();
  const arrows = createRef();
  const photoRef = createRef();
  const currentFlow = getFlowName();
  const stateQuery = props?.location?.state?.query;

  useEffect(() => {
    addTrailSlashToUrl();
    const queryParams = stateQuery ?? history.location.search;
    const parsedQuery = queryString.parse(
      queryParams[0] === '?' ? queryParams.slice(1) : queryParams
    );
    const hostname = window.location.hostname;
    const webflowNumber = hostname.includes('signup')
      ? hostname.charAt(6) === '.'
        ? 1
        : hostname.charAt(6)
      : null;
    if (parsedQuery) {
      parsedQuery.webflowNumber = webflowNumber || 'localhost';
      setAmplUserPropsFromUrlQuery(parsedQuery);
      dispatch(setUtmData({ ...parsedQuery }));
    } else if (webflowNumber) {
      amplitude.setUserProperties({ webflowNumber });
    }

    amplitudeClient.logEvent('Onboarding Viewed', {
      onboardingName: currentFlow,
      url: window?.location?.href,
    });
  }, [currentFlow, dispatch, stateQuery]);

  useEffect(() => {
    ReactPixel.track('viewedLanding');
  }, []);

  const onLoaded = () => {
    gsap
      .timeline({ repeat: -1 })
      .to(arrows.current, { duration: 1.3, ease: 'bounce.out', y: 40 })
      .to(arrows.current, { duration: 1, ease: 'power2.out', y: 0 });

    let tl = gsap.timeline();
    tl.to(document.querySelector('.preloader'), {
      duration: 0.8,
      opacity: 0,
      scale: 4,
      ease: 'power2.out',
      zIndex: -1,
    })
      .from(document.querySelector('.homepage .default-page-inner'), {
        duration: 0.3,
        opacity: 0,
        ease: 'power4.inOut',
        delay: '-0.3',
      })
      .from(photoRef.current, {
        duration: 1.2,
        x: '30px',
        opacity: 0,
        ease: 'power4.easeOut',
        delay: '-0.6',
      });

    // preload pic for the 1st step of onboarding
    setTimeout(function () {
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = srcStepPic1;
        img.onload = resolve();
        img.onerror = reject();
      });
      OnboardingPage.preload();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleOnStarted = () => {
    amplitudeClient.logEvent('Onboarding Started', {
      onboardingName: currentFlow,
      url: window?.location?.href,
    });
    history.push('onboarding');
  };

  return (
    <div className="default-page-wrapper homepage">
      <div className="default-page-inner">
        <Header2 />
        <div className="homepage__wrapper">
          <div className="homepage__details">
            <h1>How fast can you achieve your fitness goals?</h1>
            <div className="homepage__want">
              <h5>I want to:</h5>
              <div className="homepage__want_buttons">
                <div className="arrows" ref={arrows} />
                <Button
                  onClick={handleOnStarted}
                  className="btn cardiogram"
                  size="large"
                >
                  <img src={srcCardiogram} alt="cardiogram" />
                  <span>Get Fit For Good</span>
                </Button>
                <Button
                  onClick={handleOnStarted}
                  className="btn waist"
                  size="large"
                >
                  <img src={srcWaist} alt="waist" />
                  <span>Lose Weight For Good</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="homepage__bg" ref={photoRef}>
            <Picture
              sources={[
                {
                  srcSet: srcWebpXsBg,
                  media: '(max-width: 768px)',
                  type: 'image/webp',
                },
                {
                  srcSet: srcPngXsBg,
                  media: '(max-width: 768px)',
                },
                {
                  srcSet: srcPngBg,
                },
                {
                  srcSet: srcWebpBg,
                  type: 'image/webp',
                },
              ]}
              alt="Model"
              onLoad={onLoaded}
            />
          </div>
        </div>

        <div className="homapage__footer">
          <Row gutter={[24, 32]}>
            <Col span={24} sm={12}>
              <img src={srcLogo} alt="Strongher" className="logo" />
              <p className="mt-3">
                Strongher: Gym & Home workouts nutritional guidance
              </p>
              <div className="mt-1">
                <a
                  href="https://strongherapp.com/terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>{' '}
                |{' '}
                <a
                  href="https://strongherapp.com/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </Col>
            <Col span={24} sm={10} className="need-help-col">
              <img src={srcHelpIcon} alt="Help" />
              <h3 className="mt-1">Need Help?</h3>
              <p className="mt-1">
                Contact our customer support if you have any questions. We'll
                help you out.
              </p>
              <a href="mailto:support@strongherapp.co">
                support@strongherapp.co
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div className="preloader">
        <img src={srcPreloaderLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default DefaultPage;
