import amplitude from 'amplitude-js';
import queryString from 'query-string';
import history from './history';
import packageJson from '../../../package.json';

amplitude.getInstance().init('6b6a6cd105bee6cb7db8a713773ea1e8');
amplitude.setVersionName(packageJson.version);

export const setAmplUserPropsFromUrlQuery = (forcedParsedQuery = null) => {
  const parsedQuery =
    forcedParsedQuery ??
    queryString.parse(
      history.location.search[0] === '?'
        ? history.location.search.slice(1)
        : history.location.search
    );
  if (parsedQuery) amplitude.setUserProperties({ ...parsedQuery });
};

export default amplitude;
