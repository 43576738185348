import { Link } from 'react-router-dom';
import srcLogo from '../../../assets/images/strongher-logo.svg';
import './style.scss';

export default function Header2() {
  return (
    <header className="homepage-header">
      <Link to="/" className="homepage__logo">
        <img src={srcLogo} alt="Strongher" />
      </Link>
      <div className="homepage-header__links">
        <Link to="contact-us">Contact us</Link>
      </div>
    </header>
  );
}
