import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
  SET_ONBOARD: null,
  SET_EMAIL: null,
  SET_PLAN: null,
});

export const { setOnboard } = createActions(constants.SET_ONBOARD);
export const { setEmail } = createActions(constants.SET_EMAIL);
export const { setPlan } = createActions(constants.SET_PLAN);
