import axios from 'axios';
import { lazy } from 'react';
import history from './history';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const cacheImage = (srcArr) => {
  srcArr.forEach((src) => {
    /* eslint-disable no-new */
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });
};

const randomString = (length) => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const getFlowName = () => {
  const path = history.location.pathname;
  const searchKey = 'flow';
  const startIdx = path.indexOf(searchKey) + searchKey.length;
  const endIdx = searchKey.length + 2;
  return +path.slice(startIdx, endIdx);
};

const getSubscriptionId = (promocode) => {
  switch (promocode) {
    case 'WORKIT10':
      return '759m';
    case 'LETSDOMORE15':
      return 'n3nw';
    case 'TODAY20':
      return 'nfsb';
    case 'BETTERME30':
      return 'f4gr';
    default:
      return '75fm';
  }
};

const pickFoldedErrorResponse = (e, key) => {
  if (e.response) {
    const errorText =
      e.response?.data?.errors && e.response?.data?.errors[key][0];
    const messageText = e.response?.data?.message;
    return errorText || messageText;
  }
  return e;
};

const dateISO = () => {
  const date = new Date();
  return date.toISOString();
};

const addTrailSlashToUrl = () => {
  // add slash in the end of path name
  let path = history?.location?.pathname;
  const lastChar = path.slice(-1);
  if (lastChar === '/') return false;
  const key = path.slice(-3);
  path = history?.location?.pathname + history?.location?.search;
  const idx = path.indexOf(key);
  path = `${path.slice(0, idx + key.length)}/${path.slice(idx + key.length)}`;
  history.push(path);
};

const getUserCurrentCountry = async () => {
  try {
    const ACCESS_KEY = 'a429d5d18728b752a00a8f9c01a34c78';
    const publicIpAddress = 'check';
    const url = `http://api.ipstack.com/${publicIpAddress}?access_key=${ACCESS_KEY}&fields=country_code`;
    const res = await axios.get(url);
    return res;
  } catch ({ message }) {
    return null;
  }
};

const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export {
  lazyWithPreload,
  cacheImage,
  randomString,
  getFlowName,
  pickFoldedErrorResponse,
  dateISO,
  addTrailSlashToUrl,
  getSubscriptionId,
  getUserCurrentCountry,
  getOS,
};
