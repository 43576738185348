/* eslint-disable */

import { Switch, Route, Redirect } from 'react-router-dom';
import lockr from 'lockr';
import { isUndefined } from 'underscore';
import { message } from 'antd';

import NotFound from '../../pages/NotFound';

const { AUTH_KEY, LOCALSTORAGE_EXPIRES_KEY, LOCALSTORAGE_EXPIRES } =
  require('../constants').default;

const onlyAuthorisedAllowed = () => {
  const response = lockr.get(AUTH_KEY);
  if (!isUndefined(response)) {
    const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
    if (!isUndefined(authDate)) {
      const aDate = new Date(authDate);
      const aNow = new Date();
      const milliseconds = aNow - aDate;
      const difference = Math.floor(milliseconds / 1000 / 60);
      if (difference >= LOCALSTORAGE_EXPIRES) {
        message.error('Session expired. Please login again.');
        return false;
      }
    } else {
      message.error('Session expired. Please login again.');
      return false;
    }
    return true;
  }
  return false;
};

const Redirects = () => <Redirect to="/" />;

const DefaultRoute = ({ exact, path, component }) => (
  <Route exact={exact} path={path || '/'} component={component} />
);
const AuthRoute = ({ exact, path, component: Component }) => (
  <Route
    exact={exact}
    path={path || '/'}
    component={onlyAuthorisedAllowed() ? Component : Redirects}
  />
);
const LogoutRoute = ({ exact, path, persistor }) => {
  lockr.rm(AUTH_KEY);
  lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
  lockr.rm(LOCALSTORAGE_EXPIRES);

  localStorage.clear();
  lockr.flush();
  persistor.flush();
  persistor.purge();
  window.location.href = '/';
};

const CoreRouter = ({ children }) => (
  <Switch>
    {children}
    <DefaultRoute component={NotFound} />
  </Switch>
);

export {
  CoreRouter,
  onlyAuthorisedAllowed,
  DefaultRoute,
  AuthRoute,
  LogoutRoute,
};
