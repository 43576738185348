import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import lockr from 'lockr';

import ReactPixel from 'react-facebook-pixel';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import QueryString from 'qs';

import Routes from './core/routes';
import history from './core/utils/history';
import debug from './core/utils/debug';

import './styles/style.scss';
/** Actions: The necessary actions for dispatching our bootstrap values */
import { setVersion } from './core/global/globalActions';

/** The version of the app but not displayed yet */
import pack from '../package.json';

/** configureStore */
import configureStore from './core/store';

const { USER_UUID_KEY } = require('./core/constants').default;

const parsedUrlQuery = QueryString.parse(
  history.location.search[0] === '?'
    ? history.location.search.slice(1)
    : history.location.search
);
let options = { autoConfig: true, debug: false };
if (parsedUrlQuery?.id) {
  options.extern_id = parsedUrlQuery?.id;
}
ReactPixel.init('973763349383813', {}, options);
ReactPixel.pageView();
// Get bugsnag error wrapper
const ErrorWrapper = debug.getWrapper();

const globalStore = configureStore({});
const { store, persistor } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));
if (window) {
  window.hj('identify', lockr.get(USER_UUID_KEY) || null);
  const setVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(`--vh`, `${vh}px`);
  };
  const throttle = function (type, name, obj) {
    obj = obj || window;
    let running = false;
    const func = () => {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  /* init - you can init any event */
  throttle('resize', 'optimizedResize');

  // handle event
  window.addEventListener('optimizedResize', () => setVH());
  setVH();
}

const root = document.getElementById('root');
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <ErrorWrapper>
    {/* <Elements stripe={stripePromise}> */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history} basename="/">
          <Routes persistor={persistor} />
        </Router>
      </PersistGate>
    </Provider>
    {/* </Elements> */}
  </ErrorWrapper>,
  root
);
