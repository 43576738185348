import { Record } from 'immutable';

const { SET_ONBOARD, SET_EMAIL, SET_PLAN } =
  require('./onboardActions').constants;

const InitialState = Record({
  onboard: {},
  email: '',
  plan: {
    id: '75fm',
    fullPrice: 49.99,
    hasTrial: true,
    trialPrice: 1,
    duration: 3,
  },
});

const initialState = new InitialState();
/**
 * ## onboardReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function onboardReducer(
  state = initialState,
  { payload, type }
) {
  if (!(state instanceof InitialState)) return initialState.merge(state);
  switch (type) {
    case SET_ONBOARD:
      return state.set('onboard', payload);
    case SET_EMAIL:
      return state.set('email', payload);
    case SET_PLAN:
      return state.set('plan', payload);
    default:
      return state;
  }
}
